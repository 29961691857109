import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Login from '../auth/Login';
import SignUp from '../auth/SignUp';
import Register from '../auth/Register';
import ForgotPassword from '../auth/ForgotPassword';
import ResetPassword from '../auth/ResetPassword';
import AuthAction from '../auth/Action';
import OrgCreate from '../OrgCreate';
import * as ROUTES from '../../constants/routes';
import 'react-toastify/dist/ReactToastify.css';
import '../../styles/colors.css';
import '../../styles/buttons.css';
import './style.css';

const App = () => (
  <Router>
    <Fragment>
      <div className="container-fluid">
        <Switch>
          <Route exact path={ROUTES.ORG_CREATE} component={OrgCreate} />
          <Route exact path={ROUTES.LOGIN_ORG} component={Login} />
          <Route path={ROUTES.LOGIN} component={Login} />
          <Route path={ROUTES.REGISTER} component={Register} />
          <Route path={ROUTES.SIGNUP} component={SignUp} />
          <Route exact path={ROUTES.FORGOT_PW_ORG} component={ForgotPassword} />
          <Route path={ROUTES.FORGOT_PW} component={ForgotPassword} />
          <Route exact path={ROUTES.RESET_PW_ORG} component={ResetPassword} />
          <Route path={ROUTES.RESET_PW} component={ResetPassword} />
          <Route path={ROUTES.AUTH_ACTION} component={AuthAction} />
          <Route exact path={ROUTES.SLASH} component={Login} />
        </Switch>
      </div>
      <ToastContainer
        className="text-center"
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />
    </Fragment>
  </Router>
);

export default App;
