import formatUser from './formatUser';
import formatLocation from './formatLocation';
import formatOrganization from './formatOrganization';

function formatData(args, type, entry) {
  switch (type) {
    case 'user':
      return formatUser(args, entry);
    case 'location':
      return formatLocation(args);
    case 'organization':
      return formatOrganization(args);
    default:
      return false;
  }
}

export default formatData;
