import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { TURBINE_LOGO, VELA_LOGO_BLACK } from '@apprentage/constants';
import { PLATFORM_NAME } from '../../../constants/globals';
import { LOCAL_TURBINE_VELA, TURBINE_VELA } from '../../../constants/urls';
import './style.css';

const Logo = ({ src, continueUrl }) => {
  const organization = useSelector((state) => state.organization);

  const fallbackPlatformLogo = useMemo(() => {
    const decodedContinueUrl = decodeURIComponent(continueUrl);

    switch (true) {
      case decodedContinueUrl.includes(TURBINE_VELA):
      case decodedContinueUrl.includes(LOCAL_TURBINE_VELA):
        return VELA_LOGO_BLACK;
      default:
        return TURBINE_LOGO;
    }
  }, [continueUrl]);

  const url = useMemo(() => {
    if (src) {
      return src;
    }

    if (organization?.orgLogo) {
      return organization?.orgLogo;
    }

    return fallbackPlatformLogo;
  }, [fallbackPlatformLogo, organization?.orgLogo, src]);

  const name = useMemo(() => {
    if (organization?.name) {
      return organization?.name;
    }

    return PLATFORM_NAME;
  }, [organization?.name]);

  // let url = src || TURBINE_LOGO;
  // let name = PLATFORM_NAME;

  // if (organization) {
  //   const {
  //     name: orgName,
  //     orgLogo
  //   } = organization;

  //   name = orgName;
  //   if (orgLogo) {
  //     url = orgLogo;
  //   } else {
  //     url = '';
  //   }
  // }

  // if (fallbackPlatformLogo === 'vela') {
  //   url = VELA_LOGO_BLACK;
  // }

  if (url) {
    return (
      <div className="authpage-logo mb-3">
        <img src={url} alt={name} className="w-100" />
      </div>
    );
  }

  return (
    <h2 className="h5 font-weight-bold px-3 mb-3">
      {name}
    </h2>
  );
};

export default Logo;
