// import { getIdToken } from '@firebase/auth';
// import { auth } from '../firebaseConfig';
import { TW_API } from '../constants/urls';

async function request(method, endpoint, params, body) {
  if (typeof params === 'object') {
    const searchParams = new URLSearchParams(params);

    endpoint += `?${searchParams.toString()}`;
  }

  // const idToken = await getIdToken(auth.currentUser, true);
  const response = await fetch(TW_API + endpoint, {
    method: method || 'GET',
    body
    // headers: {
    //   Authorization: `FirebaseBearer ${idToken}`
    // }
  });

  return await response.json();
}

export async function get(endpoint, params) {
  return await request('GET', endpoint, params);
}

export async function put(endpoint, params, body) {
  return await request('PUT', endpoint, params, body);
}

export async function post(endpoint, params, body) {
  return await request('POST', endpoint, params, body);
}

// "delete" is a JS keyword
export async function requestDelete(endpoint, params, body) {
  return await request('DELETE', endpoint, params, body);
}
