import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery'; /* eslint-disable-line */
import Popper from 'popper.js'; /* eslint-disable-line */
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import App from './components/App';
// import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';
import Firebase, { FirebaseContext } from './components/Firebase';
import { FLAGSMITH_API_KEY } from './constants/flagsmith';

const store = configureStore();

const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <FlagsmithProvider
    options={{
      environmentID: FLAGSMITH_API_KEY
      // realtime: true
      // cacheFlags: true,
    }}
    flagsmith={flagsmith}
  >
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase()}>
        <App />
      </FirebaseContext.Provider>
    </Provider>
  </FlagsmithProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
