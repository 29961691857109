import {
  SET_USER,
  RESET_USER
} from './types';

export const setUser = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER,
      user
    });
  };
};

export const resetUser = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_USER
    });
  };
};
