import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import SupportEmailLine from '../SupportEmailLine';

const newUserNotifyOrgHtml = ({
  linkText = 'Message User',
  name,
  membership,
  userRoleReadable,
  locationTerm,
  showMembership,
  locationName,
  messageUserLink
}) => {
  let membershipInfo = '';

  if (showMembership) {
    membershipInfo = `
      <li>
        Membership: ${membership}
      </li>
    `;
  }

  return `
    <p>
      ${name} joined your Workforce Space! See details about the user below.
    </p>

    <p>
      No action is needed. If you want to say hello, send them a message.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: messageUserLink })}
    </div>

    <p>
      Some details about ${name}:
      <br />
      <ol>
        ${membershipInfo}
        <li>
          User Role: ${userRoleReadable}
        </li>
        <li>
          ${locationTerm}: ${locationName}
        </li>
      </ol>
    <p>

    ${SupportEmailLine()}

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${messageUserLink}
    </p>
  `;
};

export default newUserNotifyOrgHtml;
