import React from 'react';
import { toast } from 'react-toastify';
import { FiAlertTriangle } from 'react-icons/fi';
import Button from '@apprentage/components/dist/components/Button';
import { IMAGE_MAINTENANCE } from '@apprentage/constants';

const ConfirmationToast = ({
  onConfirm,
  title = 'Are you sure?',
  subtitle = 'You will lose all changes!',
  autoClose = false,
  closeButton = false,
  closeOnClick = false,
  iconComponent,
  handleCancel,
  handleClose = false,
  draggable = false,
  image = '',
  style
}) => {
  toast(
    <div
      className='px-4'
    >
      <span className="d-flex justify-content-center py-3 ">
        {iconComponent || <FiAlertTriangle className='text-warning' style={{ strokeWidth: '1.5' }} size={40} />}
      </span>
      <p className='text-dark'>
        {title}
      </p>

      {subtitle && (
        <p className='text-dark'>{subtitle}</p>
      )}

      {image && (
        <img
          src={IMAGE_MAINTENANCE}
          alt="Maintenance"
          className="w-100 mb-3"
        />
      )}

      {(onConfirm || handleCancel) && (
        <div className='d-flex justify-content-center py-3'>
          <Button
            className='mr-2 btn btn-primary'
            variant='primary'
            type="button"
            onClick={() => {
              toast.dismiss();
              onConfirm();
            }}
          >
            Ok
          </Button>
          <Button
            className="btn btn-outline-secondary"
            type="button"
            variant="white"
            onClick={() => {
              toast.dismiss();
              handleCancel(false);
            }}
          >
            Cancel
          </Button>
        </div>
      )}
    </div>,
    {
      ...(style ? { style } : {}),
      position: 'top-center',
      role: 'confirms',
      icon: true,
      autoClose,
      hideProgressBar: true,
      closeOnClick,
      pauseOnHover: false,
      draggable,
      progress: undefined,
      closeButton,
      onClose: handleClose
    }
  );
};

export default ConfirmationToast;
