import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import SupportEmailLine from '../SupportEmailLine';

const resetPasswordTemplate = ({
  resetPasswordLink,
  linkText = 'Choose a new password'
}) => `
  <p>
    You told us you forgot your password. If you really did, click here to choose a new one:
  </p>

  <div
    align="center"
    style="line-height:10px; padding-top:20px;padding-bottom:20px;"
  >
    ${ButtonPrimary({ text: linkText, href: resetPasswordLink })}

  </div>

  <p>
    If you didn't mean to reset your password, then you can just ignore this email; your password will not change.
  </p>

  ${SupportEmailLine()}

  ${Divider()}

  <p>
    "${linkText}" button not working?
  </p>

  <p>
    Copy and paste this link in your browser:
  </p>

  <p>
    ${resetPasswordLink}
  </p>
`;

export default resetPasswordTemplate;
