import { createEntry } from './entry';
import formatData from './formatData';

export const locationTerminology = ({ defaultLocation, orgType }) => {
  let term = 'Location';

  // Community type orgs can have a default
  // location and other locations are 'Employers'
  // If this location is marked as 'default',
  // it is always referred to as a 'Location'
  if (orgType === 'community' && !defaultLocation) {
    term = 'Employer';
  }

  return term;
};

export const createLocation = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'location');

    createEntry({
      contentType: 'location',
      data: preppedData
    })
      .then((newLocation) => {
        setTimeout(() => {
          resolve(newLocation);
        }, 250);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
