import { isEmpty } from 'ramda';

export const parseMailchimpData = (params) => {
  if (!params || isEmpty(params)) {
    return params;
  }

  const data = {
    ...params
  };

  const {
    EMAIL,
    FNAME,
    LNAME,
    PHONE,
    INFO,
    CITY, // Saved into "address" on user
    STATE // Saved into "address" on user
  } = params;

  if (EMAIL) data.userEmail = EMAIL;
  if (PHONE) data.userPhone = PHONE;
  if (INFO) data.aboutMe = INFO;

  // userName
  if (FNAME || LNAME) {
    data.userName = '';

    if (FNAME && LNAME) {
      data.userName = `${FNAME} ${LNAME}`;
    } else {
      if (FNAME) data.userName += FNAME;
      if (LNAME) data.userName += LNAME;
    }
  }

  // address
  if (CITY || STATE) {
    data.address = '';

    if (CITY && STATE) {
      data.address = `${CITY}, ${STATE}`;
    } else {
      if (CITY) data.address += CITY;
      if (STATE) data.address += STATE;
    }
  }

  return data;
};
