// Current User (contentful)
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
// User (firebase)
export const SET_USER = 'SET_USER';
export const RESET_USER = 'RESET_USER';

export const SET_ORG = 'SET_ORG';
export const SET_LOCATIONS = 'SET_LOCATIONS';

// AUTH
export const SET_AUTH_ACTION = 'SET_AUTH_ACTION';
export const RESET_AUTH_ACTION = 'RESET_AUTH_ACTION';

export const SET_SIGNUP_NOTIFY = 'SET_SIGNUP_NOTIFY';

export const SET_SIGNUP = 'SET_SIGNUP';
export const RESET_SIGNUP = 'RESET_SIGNUP';

export const SET_LOADING = 'SET_LOADING';

export const LOGOUT = 'LOGOUT';
