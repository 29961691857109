import {
  onAuthStateChanged,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  updatePassword,
  sendEmailVerification,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithPopup,
  signOut
} from '@firebase/auth';
import { getMessaging } from '@firebase/messaging';
import { getAnalytics } from '@firebase/analytics';
import { app, auth } from '../../firebaseConfig';
import { fetchUserByUid } from '../../services/user';

class Firebase {
  constructor() {
    this.analytics = getAnalytics(app);
    this.messaging = getMessaging(app);

    /**
     * GOOGLE
     *
     * https://firebase.google.com/docs/auth/web/google-signin#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const googleAuthProvider = new GoogleAuthProvider();
    // googleAuthProvider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    this.googleProvider = googleAuthProvider;

    /**
     * FACEBOOK
     *
     * https://firebase.google.com/docs/auth/web/facebook-login#handle_the_sign-in_flow_with_the_firebase_sdk
     */
    const facebookAuthProvider = new FacebookAuthProvider();
    // facebookAuthProvider.addScope('user_birthday');
    this.facebookProvider = facebookAuthProvider;
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () =>
    signInWithPopup(this.googleProvider);

  // doSignInWithFacebook = () =>
  //   signInWithPopup(this.facebookProvider);

  doSignOut = () => signOut();

  doPasswordReset = (email) => sendPasswordResetEmail(auth, email);

  doSendEmailVerification = () =>
    sendEmailVerification(auth.currentUser, {
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doPasswordUpdate = (password) =>
    updatePassword(auth.currentUser, password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    onAuthStateChanged(auth, (authUser) => {
      if (authUser) {
        let count = 0;
        let userExistsContentful = setInterval(() => {
          console.log('onAuthStateChanged', count);

          fetchUserByUid(authUser?.uid).then((response) => {
            const data = { authUser };

            if (response?.id) {
              // Login
              userExistsContentful = clearInterval(userExistsContentful);
              data.response = response;

              next(data);
            } else {
              // New Member Registration (User not in Contentful yet)
              count += 1;

              if (count >= 10) {
                userExistsContentful = clearInterval(userExistsContentful);
                // TODO stop loading
                console.error('CM:Account creation error');
                fallback();
              }
            }
          });
        }, 1000);
      } else {
        fallback();
      }
    });
}

export default Firebase;
