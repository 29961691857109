import { client, flattenItem } from './contentful';
import { createEntry } from './entry';
import formatData from './formatData';

export const fetchOrg = ({
  orgId, slug, include, select
}) => {
  const config = {
    content_type: 'organization',
    include
  };

  if (select) {
    config.select = select.join(',');
  }

  if (orgId) {
    config['sys.id'] = orgId;
  } else if (slug) {
    config['fields.slug'] = slug;
  }

  return new Promise((resolve, reject) => {
    client.getEntries(config).then(({ items }) => {
      const organization = items[0] !== undefined ? flattenItem(items[0]) : null;

      resolve(organization);
    }).catch((error) => {
      console.error(error);
      reject(error);
    });
  });
};

export const createOrg = (data) => {
  return new Promise((resolve, reject) => {
    const preppedData = formatData(data, 'organization');

    return createEntry({
      contentType: 'organization',
      data: preppedData
    }).then((response) => {
      setTimeout(() => {
        resolve(response);
      }, 250);
    }).catch((error) => {
      reject(error);
    });
  });
};
