function formatLocation({
  name,
  orgId,
  addressLine1,
  addressLine2,
  addressPostalCode,
  addressCity,
  addressState,
  addressCountry
}) {
  const data = {};

  data.enableNewEditor = { 'en-US': true };
  data.defaultLocation = { 'en-US': true };

  if (name !== undefined) {
    data.name = {
      'en-US': name
    };
  }

  if (orgId !== undefined) {
    data.orgId = {
      'en-US': orgId
    };
  }

  if (addressLine1 !== undefined) {
    data.addressLine1 = {
      'en-US': addressLine1
    };
  }

  if (addressLine2 !== undefined) {
    data.addressLine2 = {
      'en-US': addressLine2
    };
  }

  if (addressPostalCode !== undefined) {
    data.addressPostalCode = {
      'en-US': addressPostalCode
    };
  }

  if (addressCity !== undefined) {
    data.addressCity = {
      'en-US': addressCity
    };
  }

  if (addressState !== undefined) {
    data.addressState = {
      'en-US': addressState
    };
  }

  if (addressCountry !== undefined) {
    data.addressCountry = {
      'en-US': addressCountry
    };
  }

  return { fields: { ...data } };
}

export default formatLocation;
