// AUTH
export const SLASH = '/';
export const AUTH_ACTION = '/auth/action';
export const LOGIN = '/login';
export const LOGIN_ORG = '/login/:orgSlug?';
export const SIGNUP = '/signup';
export const CLASSES = '/classes'; // Used in continueUrl when an existing user is invited, but not logged in
export const REGISTER = '/register/:role/:membership/:orgId?/:locationId?/:classId?';
export const MANAGE_MEMBERSHIP = '/manage-membership';
export const FORGOT_PW = '/pw-forget';
export const FORGOT_PW_ORG = '/pw-forget/:orgSlug?';
export const RESET_PW = '/pw-reset';
export const RESET_PW_ORG = '/pw-reset/:orgSlug?';
export const ORG_CREATE = '/org-create';
