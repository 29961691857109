import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  parseName,
  roleReadable,
  roleShouldStartInTurbineAdmin
} from '@apprentage/utils';
import {
  ERROR_INVITE_MISSING_ID,
  IMAGE_MAINTENANCE,
  ROLE_STUDENT,
  ROLE_TEACHER,
  errorHumanReadable
} from '@apprentage/constants';
import { useFlags, useFlagsmithLoading } from 'flagsmith/react';
import { createUserWithEmailAndPassword } from '@firebase/auth';
import PasswordStrengthBar from 'react-password-strength-bar';
import { auth } from '../../../firebaseConfig';
import { LOGIN } from '../../../constants/routes';
import { TURBINE_ADMIN } from '../../../constants/urls';
import { deleteInvite } from '../../../services/invites';
import { updateSignUp, notifySignUp } from '../../../actions/SignUp';
import { createUser } from '../../../services/user';
import { redirectTo } from '../../../services/auth';
import AuthContainer from '../Container';
import Location from './Location';
import Phone from './Phone';
import UserGroups from './UserGroups';
import Loading from '../../Loading';
import ConfirmationToast from '../../Toasts/ConfirmationToast';
import {
  FLAG_MAINTENANCE_MODE,
  FLAG_USERS_READONLY,
  NOTICE_ACCOUNT_CREATION_BODY,
  NOTICE_MAINTENANCE_MODE_BODY,
  NOTICE_MAINTENANCE_MODE_TITLE,
  NOTICE_USERS_READONLY_TITLE
} from '../../../constants/flagsmith';

const SignUp = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const flags = useFlags([FLAG_USERS_READONLY, FLAG_MAINTENANCE_MODE]);
  const flagsStatus = useFlagsmithLoading();
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const signup = useSelector((state) => state.signup);
  // Organization
  const orgSlug = organization?.slug || '';
  const orgType = organization?.type || '';
  const loginRoute = organization?.id ? `${LOGIN}/${orgSlug}` : LOGIN;
  // Local State
  const [email, setEmail] = useState(signup?.userEmail || '');
  const [password, setPassword] = useState(signup?.userEmail || '');
  const [phone, setPhone] = useState(signup?.phone || '');
  const [locationId, setLocationId] = useState(signup?.locationId || '');
  const [loading, setLoading] = useState(false);
  const [showMaintenanceToast, setShowMaintenanceToast] = useState(false);
  const [showUsersReadonlyToast, setShowUsersReadonlyToast] = useState(false);
  const [newPassScore, setNewPassScore] = useState({
    score: 0,
    warning: '',
    suggestions: []
  });

  useEffect(() => {
    if (!flagsStatus?.isFetching) {
      if (flags?.maintenance_mode?.enabled && flags?.maintenance_mode?.value) {
        setShowMaintenanceToast(true);
      }

      if (flags?.users_readonly?.enabled && flags?.users_readonly?.value) {
        setShowUsersReadonlyToast(true);
      }
    }
  }, [flagsStatus?.isFetching, flags?.maintenance_mode, flags?.users_readonly]);

  /**
   * NAME
   */
  const fullName = useMemo(() => {
    return parseName(signup?.userName);
  }, [signup?.userName]);
  const [firstName, setFirstName] = useState(fullName?.firstName || '');
  const [lastName, setLastName] = useState(fullName?.lastName || '');
  const name = useMemo(() => {
    let result = '';

    if (firstName) result += firstName;
    if (lastName) result += ` ${lastName}`;

    return result;
  }, [firstName, lastName]);
  /**
   * SHOW CONDITIONALLY
   */
  const [showNameField, setShowNameField] = useState(!signup?.userName);
  const [showEmailField, setShowEmailField] = useState(!signup?.userEmail);

  if (!signup) {
    history.replace(LOGIN);
    return;
  }

  if (signup?.apprenticeshipId && !signup?.inviteId) {
    toast.error(errorHumanReadable(ERROR_INVITE_MISSING_ID), {
      autoClose: false
    });
    history.replace(LOGIN);
  }

  const onSubmit = (e) => {
    e.preventDefault();

    if (newPassScore.score < 2) {
      return;
    }

    if (showMaintenanceToast) {
      toast.error('Account creation disabled for maintenance', { autoClose: false });
      return false;
    }

    if (showUsersReadonlyToast) {
      toast.error('Account creation disabled for maintenance', { autoClose: false });
      return false;
    }

    if (!signup?.orgId) {
      toast.error('Organization is required!', { autoClose: false });
      return false;
    }

    if (!signup?.role) {
      toast.error('Role is required!', { autoClose: false });
      return false;
    }

    setLoading(true);
    toast.info('Processing...');

    // Create Firebase user
    createUserWithEmailAndPassword(auth, email, password)
      .then((authUser) => {
        const newMember = {
          uid: authUser?.user?.uid,
          name,
          firstName,
          lastName,
          email,
          locationId,
          orgId: signup?.orgId,
          role: signup?.role
        };

        // Form
        if (phone) {
          newMember.phone = phone;
        }

        // Signup
        if (signup?.userExternalId) {
          newMember.externalUserId = signup?.userExternalId;
        }

        // Only enroll students & teachers in Courses / Cohorts
        if (signup?.role === ROLE_STUDENT || signup?.role === ROLE_TEACHER) {
          if (signup?.classId) {
            newMember.classId = signup.classId;
          }

          if (signup?.cohortId) {
            newMember.cohortId = signup.cohortId;
          }

          if (signup?.classIds) {
            newMember.classIds = signup.classIds;
          }
        }
        // /END

        if (signup?.apprenticeshipId) {
          newMember.apprenticeshipId = signup.apprenticeshipId;
        }

        if (signup?.apprenticeshipIds) {
          newMember.apprenticeshipIds = signup.apprenticeshipIds;
        }

        if (signup?.membership) {
          newMember.membership = signup.membership;
        }

        if (signup?.validUserGroupIds) {
          newMember.groupIds = signup.validUserGroupIds; // validated userGroupIds
        }

        // Mailchimp Integration (mce)
        if (signup?.aboutMe) {
          newMember.aboutMe = signup.aboutMe;
        }

        if (signup?.address) {
          newMember.address = signup.address;
        }

        toast.info('Creating account...');

        // Create Contentful User (linked by uid)
        createUser(newMember).then((response) => {
          const userId = response?.id;

          // Delete Apprenticeship Invitation
          if (signup?.apprenticeshipId && signup?.inviteId) {
            deleteInvite(signup?.inviteId);
          }

          // Notify Org & Send welcome email
          dispatch(
            notifySignUp({
              email: newMember.email,
              name: newMember.name,
              userId,
              organization,
              locations,
              signup
            })
          )
            .then(() => {
              toast.success('Account created!');

              const data = {
                email,
                password
              };

              /**
               * Administrative Users are redirected to Turbine Admin
               *
               * owner = Organization Settings
               * programManger = Apprenticeship > Users
               * programTrainer = Apprenticeship > Users
               */
              if (roleShouldStartInTurbineAdmin([signup?.role])) {
                data.continueUrl = signup?.continueUrl || TURBINE_ADMIN;
              }

              // Redirect to LMS or continueUrl (based on role)
              redirectTo(data);
            })
            .catch((error) => {
              setLoading(false);
              console.error(error);
              toast.error('Something went wrong, please try again.', {
                autoClose: false
              });
            });
        });
        // TODO add catch and email turbine support.
      })
      .catch(({ message }) => {
        setLoading(false);
        toast.error(message, { autoClose: false });
      });
  };

  if (!organization?.id || !locations) {
    return <Loading />;
  }

  if (showMaintenanceToast) {
    return (
      <AuthContainer>
        <ConfirmationToast
          title={NOTICE_MAINTENANCE_MODE_TITLE}
          subtitle={NOTICE_MAINTENANCE_MODE_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </AuthContainer>
    );
  }

  if (showUsersReadonlyToast) {
    return (
      <AuthContainer>
        <ConfirmationToast
          title={NOTICE_USERS_READONLY_TITLE}
          subtitle={NOTICE_ACCOUNT_CREATION_BODY}
          image={IMAGE_MAINTENANCE}
        />
      </AuthContainer>
    );
  }

  return (
    <AuthContainer
      title={`${roleReadable([signup?.role], orgType)} Registration`}
    >
      <form name="signup" onSubmit={onSubmit}>
        <div className="col-sm-12">
          <Location
            locationId={locationId}
            handleChange={({ locationId: newLocationId }) => {
              setLocationId(newLocationId);
              dispatch(
                updateSignUp({
                  locationId: newLocationId
                })
              );
            }}
          />

          {/* NAME */}
          <div className="mb-3 text-left">
            <div className="text-muted small">
              <label
                htmlFor="firstName"
                className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
              >
                {showNameField ? 'First Name' : 'Name'}

                {!showNameField && (signup?.canChange || signup?.canChangeName) && (
                  <button
                    type="button"
                    className="btn-link p-0 ml-2"
                    onClick={() => {
                      setShowNameField(true);
                    }}
                  >
                    Change
                  </button>
                )}
              </label>

              {!showNameField && <p className="m-0">{name}</p>}

              <input
                type={showNameField ? 'text' : 'hidden'}
                id="firstName"
                name="firstName"
                className="form-control"
                value={firstName}
                autoFocus={showNameField}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setFirstName(value);
                }}
                required
              />
            </div>
          </div>

          <div
            className="mb-3 text-left"
            style={{
              display: showNameField ? 'block' : 'none'
            }}
          >
            <div className="text-muted small">
              <label
                htmlFor="lastName"
                className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
              >
                Last Name
              </label>
              <input
                type={showNameField ? 'text' : 'hidden'}
                id="lastName"
                name="lastName"
                className="form-control"
                value={lastName}
                onChange={(e) => {
                  const { value } = e.currentTarget;

                  setLastName(value);
                }}
                required
              />
            </div>
          </div>
          {/* /NAME */}

          <Phone
            onChange={(newPhone) => {
              setPhone(newPhone);
            }}
          />
          <UserGroups />

          <div className="mb-3 text-left">
            <div className="text-muted pb-3 small">
              <label
                htmlFor="email"
                className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
              >
                {signup?.role === 'owner' && 'Work'} Email
                {!showEmailField && (signup?.canChange || signup?.canChangeEmail) && (
                  <button
                    type="button"
                    className="btn-link p-0 ml-2"
                    onClick={() => {
                      setShowEmailField(true);
                    }}
                  >
                    Change
                  </button>
                )}
              </label>

              {!showEmailField && <p className="m-0">{signup?.userEmail}</p>}

              <input
                type={showEmailField ? 'email' : 'hidden'}
                id="email"
                name="email"
                className="form-control"
                autoComplete="new-email"
                placeholder={email || ''}
                defaultValue={email}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setEmail(value);
                }}
                required
              />
            </div>

            <div className="text-muted small">
              <label
                htmlFor="password"
                className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
              >
                Password
              </label>

              <input
                type="password"
                id="password"
                name="password"
                className="form-control"
                placeholder=""
                autoComplete="new-password"
                required
                minLength={12}
                autoFocus={signup?.userName && signup?.userEmail}
                onChange={(e) => {
                  const { value } = e.currentTarget;
                  setPassword(value);
                }}
              />
              <PasswordStrengthBar
                password={password}
                minLength={12}
                onChangeScore={(score, feedback) => {
                  setNewPassScore({
                    score,
                    warning: feedback.warning,
                    suggestions: feedback.suggestions
                  });
                }}
              />
            </div>
          </div>
        </div>

        <button
          disabled={loading}
          title="Create Account"
          className="btn btn-md btn-primary mt-2 mb-3"
          type="submit"
        >
          {loading ? 'Creating Account...' : 'Create Account'}
        </button>

        <div className="mt-4">
          Already have an account?
          <Link
            className="ml-2 btn-link text-nowrap"
            to={loginRoute}
            title="Sign In"
          >
            Sign In
          </Link>
        </div>
      </form>
    </AuthContainer>
  );
};

export default SignUp;
