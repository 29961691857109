import { toast } from 'react-toastify';
import { FIELDS_LOCATION } from '../constants/contentful';
import { fetchOrg } from '../services/organizations';
import { getLocations } from './Locations';
import { SET_ORG } from './types';

export const getOrg = ({
  orgId, slug, select, include = 1
}) => {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      if (!orgId && !slug) {
        const orgFailedError = 'Missing required Organization identifier';

        toast.error(orgFailedError);
        reject(new Error(orgFailedError));
      }

      fetchOrg({
        orgId, slug, select, include
      }).then((organization) => {
        const courseGroupIds = organization?.courseGroupIds || null;

        if (!organization?.id) {
          toast.error('Invalid Organization identifier', { autoClose: false });
          reject('Invalid Organization identifier');
        }

        // SET LOCATIONS by ORG ID
        dispatch(getLocations({
          orgId: organization?.id,
          select: FIELDS_LOCATION
        })).then((locations) => {
          dispatch({ type: SET_ORG, organization });

          resolve({
            locations,
            courseGroupIds,
            organization
          });
        });
      }).catch((error) => {
        console.error(error);
        toast.error(error.message);
        reject(error);
      });
    });
  };
};
