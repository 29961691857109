import React, {
  useCallback, useEffect, useRef, useState
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isStudent, isTeacher } from '@apprentage/utils';
import {
  signOut,
  signInWithEmailAndPassword
} from '@firebase/auth';
import { auth } from '../../../firebaseConfig';
import { setAuthAction, resetUser } from '../../../actions';
import { CLASSES, FORGOT_PW } from '../../../constants/routes';
import { redirectTo } from '../../../services/auth';
import { fetchUserByUid, updateUser } from '../../../services/user';
import AuthContainer from '../Container';
import RegisterLink from './RegisterLink';
import { deleteInvite } from '../../../services/invites';
import { IMAGE_KEYSTONE_SPACE_LOGO } from '../../../constants/assets';

const Login = () => {
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const signup = useSelector((state) => state.signup);
  // Local state
  const [loading, setLoading] = useState(false);
  // Refs
  const emailFieldRef = useRef(null);
  // Organization
  const orgSlug = organization?.slug || '';
  const orgId = organization?.id || '';
  // Forgot Password
  const forgotPasswordRoute = orgId ? `${FORGOT_PW}/${orgSlug}` : FORGOT_PW;
  // Search Params
  const searchParams = new URLSearchParams(window.location.search);
  let continueUrl = searchParams.get('continueUrl') || '';
  // DEMO
  const keystoneSpaceDemo = continueUrl ? continueUrl.includes('demo=keystonespace') : null;
  // /DEMO

  /**
   * Signup
   */
  const inviteId = signup?.inviteId || '';
  // Apprenticeship
  const apprenticeshipId = signup?.apprenticeshipId || '';
  // User
  const userRole = signup?.userRole || '';
  const userEmail = signup?.userEmail || '';
  // Course(s)
  const classId = signup?.classId || '';
  const classIds = signup?.classIds || '';
  const cohortId = signup?.cohortId || '';
  const eUnitIds = signup?.eUnitIds || ''; // Education Units (eUnits)
  const extRegClassId = signup?.extRegClassId || ''; // External Course Registration Queue
  const wc = signup?.wc || ''; // Waive Costs
  const io = signup?.io || ''; // Invite Only
  const pcp = signup?.pcp || ''; // Public Course Page

  const logoutUser = useCallback(() => {
    signOut(auth).then(() => {
      // dispatch(logout());
      dispatch(resetUser());
    });
  }, [dispatch]);

  useEffect(() => {
    logoutUser();

    // TODO: Show Login screen if userEmail is already registered
    // fetchUser by email and redirect user to Login screen
  }, [logoutUser]);

  const onSubmit = (e) => {
    e.preventDefault();

    let password = e?.currentTarget?.password?.value;
    let email = e?.currentTarget?.email?.value;

    // DEMO
    if (keystoneSpaceDemo) {
      email = 'dustybuckingham@gmail.com';
      password = '!PnCUeskN0dY';
    }
    // /DEMO

    setLoading(true);

    signInWithEmailAndPassword(auth, email, password).then(({ user }) => {
      console.log('user', user);
      if (apprenticeshipId && orgSlug) {
        // APPRENTICESHIP INVITE
        fetchUserByUid(user?.uid).then((response) => {
          const dataToSave = {
            apprenticeshipId
          };

          if (!response?.id) {
            toast.error('Something went wrong, click the invite link to try again.', { autoClose: false });

            return false;
          }

          // If user is logged in and userEmail is present
          // And userEmail is different than logged in user
          // Redirect to SLASH (dashboard)
          if (userEmail && response?.id && response?.email !== userEmail) {
            toast.error('Invitation Error: Recipient mismatch', { autoClose: false });
            return false;
          }

          // If role is present and is trainer or manager of a training program
          // allow the user
          if (userRole && (userRole === 'programTrainer' || userRole === 'programManager')) {
            dataToSave.role = userRole;
          }

          // Only enroll students & teachers in Courses
          if (classIds) {
            // New Invitations will always have a role, support legacy
            // Check if student or teacher before enrolling user in courses
            if (!userRole || isStudent([userRole]) || isTeacher([userRole])) {
              if (Array.isArray(classIds) && classIds.length > 0) {
                dataToSave.classIds = classIds;
              }

              if (classIds.includes(',')) {
                dataToSave.classIds = classIds.split(',');
              }
            }
          }

          toast.info('Joining Training Program...');

          updateUser(dataToSave, response?.id).then(() => {
            // TODO fetch apprenticeship and show apprenticeship title
            toast.success('Successfully joined Training Program!');

            deleteInvite(inviteId).then(() => {
              redirectTo({
                email,
                password,
                continueUrl
              });
            });
          });
        });
      } else if (classId && orgSlug) {
        // COURSE INVITE
        // User was invited to join a course
        // and chose Login (presumably an existing user)

        // Redirect User to class Dashboard after login
        continueUrl = `${CLASSES}/${classId}`;

        // Waive Costs || Invitation Only || Public Course Page
        // TODO support External Register
        if (wc || io || pcp) {
          toast.info('Processing invitation...');

          fetchUserByUid(user?.uid).then((response) => {
            const dataToSave = {
              classId
            };

            if (cohortId) dataToSave.cohortId = cohortId;

            if (eUnitIds) {
              if (Array.isArray(eUnitIds) && eUnitIds.length > 0) {
                dataToSave.eUnitIds = eUnitIds;
              }

              if (eUnitIds.includes(',')) {
                dataToSave.eUnitIds = eUnitIds.split(',');
              }
            }

            if (extRegClassId) dataToSave.extRegClassId = extRegClassId;

            if (!response?.id) {
              toast.error('Something went wrong, click the invite link to try again.', { autoClose: false });

              return false;
            }

            updateUser(dataToSave, response?.id).then(() => {
              toast.success('Success!');

              redirectTo({
                email,
                password,
                continueUrl
              });
            });
          });
        } else {
          // TODO the continue URL could be the login/invitation url
          // Currently there is some logic will prevents this from working
          // take another approach with a dedicated invite route.
          // continueUrl = `/login/${orgSlug}?action=signup&classId=${classId}`;
          // if (cohortId) continueUrl += `&cohortId=${cohortId}`;
          // if (eUnitIds) continueUrl += `&eUnitIds=${eUnitIds}`;

          redirectTo({
            email,
            password,
            continueUrl
          });
        }
      } else {
        // Regular Login Flow
        console.log('Regular Login Flow');
        redirectTo({
          email,
          password,
          continueUrl
        });
      }
    }).catch(({ message }) => { // code
      toast.error(message, { autoClose: false });

      setLoading(false);
    });
  };

  const tempSavePw = () => {
    dispatch(setAuthAction({
      email: emailFieldRef.current.value
    }));
  };

  const showEmailField = !userEmail;

  return (
    <AuthContainer title="Login">
      <form
        method="post"
        onSubmit={onSubmit}
      >
        {/* DEMO */}
        {keystoneSpaceDemo && (
          <div
            className='position-absolute bg-white shadow border rounded p-3 d-flex align-items-center mb-3'
            style={{ zIndex: 1 }}
          >
            <img
              src={IMAGE_KEYSTONE_SPACE_LOGO}
              height={50}
              style={{
                height: '50px'
              }}
              alt='Keystone Space Collective'
            />
            <div className='ml-3 text-left'>
              <div className='font-weight-bold'>
                Keystone Space Conference
              </div>
              <div>
                Demo Account
              </div>
            </div>
          </div>
        )}
        {/* /DEMO */}

        <div
          className={`pb-2 text-muted mb-2 ${keystoneSpaceDemo ? 'pointer-events-none' : ''}`}
        >
          <div
            className={`mb-1 ${keystoneSpaceDemo ? 'd-none' : 'd-flex align-items-center'}`}
          >
            <label
              htmlFor="email"
              className="m-0 text-left text-gray-dark font-weight-bold"
            >
              Email
            </label>
            {!showEmailField && (
              <button
                type="button"
                className="btn-link p-0 ml-2"
                onClick={() => {
                  document.location.reload();
                }}
              >
                Change
              </button>
            )}
          </div>

          {!showEmailField && (
            <p className="mb-1 text-left">{userEmail}</p>
          )}

          <input
            type={showEmailField ? 'email' : 'hidden'}
            style={{
              ...(keystoneSpaceDemo ? { filter: 'blur(10px)' } : {})
            }}
            id="email"
            name="email"
            className="form-control"
            defaultValue={userEmail || ''}
            ref={emailFieldRef}
            required={!keystoneSpaceDemo}
            autoFocus
          />
        </div>
        <div
          className="pb-1 mb-2"
        >
          <div
            className={`${keystoneSpaceDemo ? 'd-none' : 'd-flex justify-content-between align-items-center'}`}
          >
            <label
              htmlFor="password"
              className="w-100 text-left text-gray-dark mb-1 font-weight-bold text-muted"
            >
              Password
            </label>
            <Link
              className="btn btn-sm btn-link text-right pr-0 text-nowrap"
              to={forgotPasswordRoute}
              title="Forgot Password?"
              onClick={tempSavePw}
              tabIndex={-1}
            >
              Forgot Password
            </Link>
          </div>

          <input
            type="password"
            id="password"
            name="password"
            className="form-control"
            required={!keystoneSpaceDemo}
            style={{
              ...(keystoneSpaceDemo ? { filter: 'blur(10px)' } : {})
            }}
          />
        </div>

        <button
          disabled={loading}
          className="btn btn-md btn-primary mt-2"
          type="submit"
          title="Sign in"
        >
          {loading ? 'Signing In...' : 'Sign in'}
        </button>

        <RegisterLink />
      </form>

    </AuthContainer>
  );
};

export default Login;
