import React from 'react';
import './style.css';

const Loading = ({ text, className = '' }) => {
  return (
    <div
      className={`turbine-loading ${className}`}
    >
      <i
        className="fas fa-fan fa-spin large"
        aria-hidden="true"
        style={{ fontSize: '3rem' }}
      />
      <div>
        {text || 'Loading'}
      </div>
    </div>
  );
};

export default Loading;
