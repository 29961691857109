import { clientManagement, CSPACE } from './contentful';

export const createEntry = ({ contentType, data }) => {
  return new Promise((resolve, reject) => {
    return clientManagement.getSpace(CSPACE)
      .then((space) => space.getEnvironment('master'))
      .then((environment) => environment.createEntry(contentType, data))
      .then((entry) => entry.publish())
    // .then((entry) => console.log(`Entry ${entry.sys.id} published.`))
      .then((entry) => {
        resolve(entry);
      })
      .catch((error) => {
        console.error(error);
        reject(error);
      });
  });
};
