import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateSignUp } from '../../../actions';
import { getUserGroups } from '../../../services/users';

const UserGroups = () => {
  const dispatch = useDispatch();
  // Redux
  const signup = useSelector((state) => state.signup);
  // Signup
  const userGroupIds = signup?.userGroupIds || null;
  const validUserGroupIds = signup?.validUserGroupIds || null;
  // Local State
  const [userGroups, setUserGroups] = useState(null);
  const [userGroupsFetched, setUserGroupsFetched] = useState(false);

  const userGroupsReadable = useMemo(() => {
    if (Array.isArray(userGroups)) {
      return userGroups.map((ug) => ug?.title);
    }

    return '';
  }, [userGroups]);

  useEffect(() => {
    let mounted = true;

    if (!userGroups && !userGroupsFetched && userGroupIds) {
      getUserGroups({ groupIds: userGroupIds }).then((userGroupsList) => {
        if (mounted) {
          const validUserGroupsList = userGroupsList.filter((g) => userGroupIds.includes(g.id));
          const validUserGroupsListIds = validUserGroupsList.length > 0 ? validUserGroupsList.map((vug) => vug.id) : null;

          setUserGroupsFetched(true);
          setUserGroups(validUserGroupsList.length > 0 ? validUserGroupsList : null);
          dispatch(updateSignUp({ validUserGroupIds: validUserGroupsListIds }));
        }
      });
    }

    return function cleanup() {
      mounted = false;
    };
  });

  if (!validUserGroupIds) return null;

  return (
    <div className="mb-3 text-left">
      <div className="text-muted small">
        <label
          htmlFor="userGroups"
          className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
        >
          User {validUserGroupIds.length === 1 ? 'Group' : 'Groups'}
        </label>

        {userGroupsReadable && (
          <p className="m-0">
            {userGroupsReadable.join(', ')}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserGroups;
