import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { pathOr } from 'ramda';
import { confirmPasswordReset } from '@firebase/auth';
import { auth } from '../../../firebaseConfig';
import { LOGIN } from '../../../constants/routes';
import { resetAuthAction } from '../../../actions';
import { redirectTo } from '../../../services/auth';
import AuthContainer from '../Container';

const ResetPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const authAction = useSelector((state) => state.authAction);
  // Misc
  const orgSlug = pathOr(null, ['slug'], organization);
  const loginUrl = (organization) ? `${LOGIN}/${orgSlug}` : LOGIN;
  const actionCode = pathOr(null, ['actionCode'], authAction);
  const email = pathOr(null, ['email'], authAction);

  useEffect(() => {
    return function cleanup() {
      dispatch(resetAuthAction());
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    const { password } = e.currentTarget;

    // Save the new password.
    confirmPasswordReset(auth, actionCode, password.value).then(() => {
      // Password reset has been confirmed and new password updated.

      toast.success('Your password was reset.');

      redirectTo({ email, password: password.value });

      // TODO: If a continue URL is available, display a button which on
      // click redirects the user back to the app via continueUrl with
      // additional state determined from that URL's parameters.
    }).catch(({ message }) => { // code
      // Error occurred during confirmation. The code might have expired or the
      // password is too weak.
      toast.error(message, { autoClose: false });
    });
  };

  if (!authAction || (authAction && !authAction.actionCodeChecked)) {
    history.replace(loginUrl);

    return false;
  }

  return (
    <AuthContainer title="Reset Password">
      <>
        <p className="text-left small">
          for <b>{authAction.email}</b>
        </p>

        <form
          method="post"
          onSubmit={onSubmit}
        >
          <div className="pb-2 text-muted mb-2">
            <label
              htmlFor="email"
              className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
            >
              New Password
            </label>
            <input
              type="password"
              id="password"
              name="password"
              className="form-control"
              required
              autoFocus
            />
          </div>

          <button
            className="btn btn-md btn-primary btn-block my-2"
            type="submit"
            title="Reset Password"
          >
            Reset Password
          </button>
          <Link
            className="btn btn-sm btn-link text-right text-nowrap"
            to={loginUrl}
            title="Cancel"
          >
            Cancel
          </Link>
        </form>
      </>
    </AuthContainer>
  );
};

export default ResetPassword;
