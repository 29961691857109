import React from 'react';
import { useSelector } from 'react-redux';
import { TURBINE_PRIVACY_URL, TURBINE_TOS_URL } from '../../../constants/urls';

function privacyPolicy(organization) {
  let url = TURBINE_PRIVACY_URL;

  if (organization?.privacyPolicy) {
    url = organization?.privacyPolicy;
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn-link"
      href={url}
    >
      Privacy Policy
    </a>
  );
}

function termsOfService(organization) {
  let url = TURBINE_TOS_URL;

  if (organization) {
    const { tos } = organization;

    if (tos) url = tos;
  }

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="btn-link"
      href={url}
    >
      Terms
    </a>
  );
}

const Legal = () => {
  const organization = useSelector((state) => state.organization);

  return (
    <small>
      By signing in you agree to our {privacyPolicy(organization)} and {termsOfService(organization)}.
    </small>
  );
};

export default Legal;
