import React, { useState } from 'react';
import Select from 'react-select';

// const options = [
//   { value: 'chocolate', label: 'Chocolate' },
//   { value: 'strawberry', label: 'Strawberry' },
//   { value: 'vanilla', label: 'Vanilla' },
// ];

const SelectMenu = ({
  name,
  options,
  onChange,
  defaultValue,
  isMulti = false
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultValue);

  const handleFieldChange = (data) => {
    setSelectedOption(data);

    if (isMulti) {
      const result = data.map((d) => d.value);

      onChange({ [name]: result });
    } else {
      onChange({ [name]: data.value });
    }
  };

  return (
    <Select
      value={selectedOption}
      onChange={handleFieldChange}
      options={options}
      isMulti={isMulti}
    />
  );
};

export default SelectMenu;
