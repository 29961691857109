import { pathOr } from 'ramda';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  reactSelectDefaultValue,
  reactSelectOptionsFromArray
} from '@apprentage/utils';
import { locationTerminology } from '../../../services/locations';
import SelectMenu from '../../ManageContent/SelectMenu';

const Location = ({ locationId, handleChange }) => {
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  const signup = useSelector((state) => state.signup);
  // Organization
  const orgType = pathOr('', ['type'], organization);

  const locationOptions = useMemo(() => {
    const allowedLocations = !locations ? [] : [...locations];
    // if (!canAccessAllLocations(role)) {
    //   allowedLocations = allowedLocations.filter((l) => !l.defaultLocation);
    // }
    return reactSelectOptionsFromArray(allowedLocations, 'name');
  }, [locations]);
  const locationDefaultValue = reactSelectDefaultValue(
    locationId,
    locationOptions
  );
  const selectedLocation = useMemo(() => {
    if (locationDefaultValue) {
      return locationDefaultValue[0];
    }

    return null;
  }, [locationId, locations, locationDefaultValue]);

  const {
    userLocationId, hideLocation, canChange, canChangeLocation
  } = signup;
  const [showLocationField, setShowLocationField] = useState(!userLocationId);

  const locationTerm = locationTerminology({
    defaultLocation: selectedLocation?.defaultLocation,
    orgType
  });

  return (
    <>
      {selectedLocation?.value && locations && locations.length > 1 && (
        <div className={`d-${hideLocation ? 'none' : 'block'} mb-3 text-left`}>
          <div className="text-muted small">
            <label
              htmlFor="locationId"
              className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
            >
              {locationTerm}

              {!hideLocation
                && !showLocationField
                && (canChange || canChangeLocation) && (
                  <button
                    type="button"
                    className="btn-link p-0 ml-2"
                    onClick={() => {
                      setShowLocationField(true);
                    }}
                  >
                    Change
                  </button>
              )}
            </label>

            {!showLocationField && userLocationId ? (
              <>
                <p className="m-0">{selectedLocation?.label}</p>
              </>
            ) : (
              <SelectMenu
                id="locationId"
                name="locationId"
                autoComplete="new-location"
                options={locationOptions}
                defaultValue={locationDefaultValue}
                onChange={handleChange}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Location;
