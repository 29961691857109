import React from 'react';

const Success = ({ userEmail, orgName }) => {
  return (
    <div>
      <h4>
        <strong>{orgName}</strong>
      </h4>
      <h5 className='mb-3'>
        Workforce Space created <i className="fas fa-check-circle text-success" />
      </h5>
      <p>
        We sent an email to <strong>{userEmail}</strong> with instructions to finish creating your account.
      </p>
    </div>
  );
};

export default Success;
