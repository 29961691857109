import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getOrg, setSignUp } from '../../../actions';
import { FIELDS_ORG } from '../../../constants/contentful';
import { SIGNUP } from '../../../constants/routes';
import Loading from '../../Loading';

const Register = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const signup = useSelector((state) => state.signup);
  // Params
  const locationId = params?.locationId || '';
  const orgId = params?.orgId || '';
  const membership = params?.membership || '';
  const classId = params?.classId || '';

  useEffect(() => {
    if (locationId) {
      // Users reach Register via deep link after external payment integration
      // See route params for available data: /constants/routes
      /**
       * Enrollment Data:
       *
       * Learn more: https://github.com/apprentage/docs-internal/blob/main/AUTH.md#enrollment-data
       *
       */

      dispatch(
        setSignUp({
          role: 'student', // params?.role,
          orgId,
          canChangeLocation: false,
          hideLocation: true,
          action: 'register',
          membership,
          classId,
          locationId,
          userLocationId: locationId
        })
      ).then(() => {
        if (orgId) {
          dispatch(
            getOrg({
              orgId,
              select: FIELDS_ORG
            })
          );
        }
      });
    }

    return function cleanup() {
      document.getElementsByTagName('html')[0].className = '';
    };
  }, []);

  useEffect(() => {
    // Only proceed if org and signup are in redux
    if (organization && signup) {
      history.replace(SIGNUP);
    }
  }, [organization, signup]);

  return <Loading />;
};

export default Register;
