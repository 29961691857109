import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pathOr } from 'ramda';
import { toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';
import { resetAuthAction } from '../../../actions';
import { LOGIN } from '../../../constants/routes';
import { resetPasswordEmail } from '../../../services/mailer';
import { generatePasswordResetLink } from '../../../services/auth';
import { decodePayload } from '../../../services/payload';
import AuthContainer from '../Container';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  // Redux
  const organization = useSelector((state) => state.organization);
  const authAction = useSelector((state) => state.authAction);
  // Local State
  const [loading, setLoading] = useState(false);
  // Misc
  const orgName = pathOr(null, ['name'], organization);
  const orgLogo = pathOr(null, ['orgLogo'], organization);
  const orgSlug = pathOr(null, ['slug'], organization);
  const login = organization ? `${LOGIN}/${orgSlug}` : LOGIN;

  useEffect(() => {
    return function cleanup() {
      dispatch(resetAuthAction());
    };
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    const { email } = e.currentTarget;

    setLoading(true);

    generatePasswordResetLink(email.value).then((encodedPayload) => {
      // Notify User
      toast.info('We sent you a link to reset your password.', { autoClose: false });

      // Redirect user to Login Screen
      history.replace(login);

      decodePayload(encodedPayload).then((payload) => {
        if (payload && payload.link) {
          resetPasswordEmail({
            resetPasswordLink: payload.link,
            email: email.value,
            ...(orgName ? { orgName } : {}),
            ...(orgLogo ? { orgLogo } : {})
          });
        }
      });
    });
  };

  return (
    <AuthContainer title="Forgot Password">
      <Fragment>
        <p>
          Enter the email address associated with your account and we'll send you a link to reset your password.
        </p>
        <form
          method="post"
          onSubmit={onSubmit}
        >
          <div className="pb-2 text-muted mb-2">
            <label
              htmlFor="email"
              className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              className="form-control"
              placeholder="Email address"
              defaultValue={(authAction && authAction.email) || ''}
              required
              autoFocus
            />
          </div>
          <button
            className="btn btn-md btn-primary btn-block my-2"
            type="submit"
            title="Continue"
            disabled={loading}
          >
            {loading ? 'Processing...' : 'Continue'}
          </button>
          <Link
            className="btn btn-sm btn-link text-right text-nowrap"
            to={login}
            title="Cancel"
            disabled={loading}
          >
            Cancel
          </Link>
        </form>
      </Fragment>
    </AuthContainer>
  );
};

export default ForgotPassword;
