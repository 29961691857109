import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { isOwner } from '@apprentage/utils';
import { errorHumanReadable } from '@apprentage/constants';
import { getOrg, setSignUp } from '../../../actions';
import { LOGIN, SIGNUP } from '../../../constants/routes';
import { locationSignUpParams } from '../../../services/auth';
import { fetchOrgUsers } from '../../../services/users';
import { roleSignUpParam } from '../../../services/user';
import { decodePayload } from '../../../services/payload';
import { parseMailchimpData } from '../Register/integrations';
import { FIELDS_ORG } from '../../../constants/contentful';
import Container from '../../Container';
import Loading from '../../Loading';

const AuthContainer = ({ children, title }) => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const signup = useSelector((state) => state.signup);
  // Misc
  const searchParams = Object.fromEntries(
    new URLSearchParams(window.location.search)
  );
  const orgSlug = params?.orgSlug || '';
  const errorCode = searchParams?.errorCode || '';

  // Payload
  const { spice, continueUrl } = searchParams;

  useEffect(() => {
    if (errorCode) {
      toast.error(errorHumanReadable(errorCode), { autoClose: false });
    }

    if (!organization?.id && orgSlug) {
      dispatch(
        getOrg({
          slug: orgSlug,
          select: FIELDS_ORG
        })
      ).then((response) => {
        const orgId = response?.organization?.id || '';

        if (spice) {
          // The "spice" URL parameter contains a JWT token to simplify passing data between apps

          decodePayload(spice).then((payload) => {
            /**
             * "payload" contains Enrollment Data:
             *
             * Learn more: https://github.com/apprentage/docs-internal/blob/main/AUTH.md#enrollment-data
             *
             */
            const source = payload?.source || '';
            const locationParams = locationSignUpParams({
              orgType: response?.organization?.type,
              locations: response?.locations,
              userLocationId: payload?.userLocationId,
              canChangeLocation: payload?.canChangeLocation
            });

            if (payload?.action === 'signup') {
              const dataToSave = {
                continueUrl,
                ...payload,
                ...(source === 'mce' ? parseMailchimpData(payload) : {}),
                ...locationParams,
                orgId,
                role: roleSignUpParam(payload?.userRole),
                membership: 'starter'
              };

              if (isOwner([payload?.role])) {
                /**
                 * Owner Registration
                 *
                 * the "owner" Role can only be externally assigned once. If an Organization
                 * would like to invite an additional Owner, they must invite the user
                 * as a different Role, then change the user's Role within Turbine
                 */
                fetchOrgUsers({ orgId }).then((orgUsers) => {
                  if (orgUsers) {
                    // Has Owners
                    toast.error(
                      'This organization has an owner, permission denied.'
                    );
                    history.replace(LOGIN);
                  } else {
                    dataToSave.role = payload?.role; // 'owner'
                    // No owners, continue registration
                    dispatch(setSignUp(dataToSave)).then(() => {
                      history.replace(SIGNUP);
                    });
                  }
                });
              } else {
                // All Other Roles
                dispatch(setSignUp(dataToSave)).then(() => {
                  history.replace(SIGNUP);
                });
              }
            }
          });
        }
      });
    }

    // /Payload

    return function cleanup() {
      document.getElementsByTagName('html')[0].className = '';
    };
  }, [continueUrl, dispatch, errorCode, history, orgSlug, organization?.id, spice]);

  if (orgSlug && !organization?.id) {
    return <Loading />;
  }

  return (
    <Container
      title={title}
      className={signup?.role || ''}
      continueUrl={continueUrl}
    >
      {children}
    </Container>
  );
};

export default AuthContainer;
