import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';
import { PLATFORM_NAME } from '../../../constants/globals';
import SupportEmailLine from '../SupportEmailLine';

const newOrgWelcomeHtml = ({
  linkText = 'Create Owner Account',
  orgName,
  name,
  registerLink
}) => {
  return `
    <p>
      Hi ${name},
    </p>
    <p>
      Welcome to ${PLATFORM_NAME}.
    </p>
    <p>
      Next step, create a user account for ${orgName}'s Workforce Space.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: registerLink })}
    </div>

    ${SupportEmailLine()}

    <p>
      Team ${PLATFORM_NAME}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${registerLink}
    </p>
  `;
};

export default newOrgWelcomeHtml;
