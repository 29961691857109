import * as twApi from './tw-api';
import { FIREBASE_STORE_COLLECTION } from '../constants/api';

/**
 * Delete firebase document by id
 *
 * @param {} params
 * @param {string} collection - firebase collection name
 * @param {string} id - firebase document id
 * @returns {Promise<string>}
 */
export async function deleteFirebaseEntry({ collection, id }) {
  if (!id) {
    return false;
  }

  const response = await twApi.requestDelete(
    `${FIREBASE_STORE_COLLECTION}/${collection}`,
    { id }
  );

  return response;
}
