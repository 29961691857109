import {
  SET_USER,
  RESET_USER,
  SET_AUTH_ACTION,
  RESET_AUTH_ACTION,
  SET_CURRENT_USER,
  SET_SIGNUP,
  RESET_SIGNUP,
  SET_SIGNUP_NOTIFY,
  SET_ORG,
  SET_LOADING,
  LOGOUT,
  SET_LOCATIONS
} from '../actions/types';

const initialState = {
  authAction: null,
  organization: null,
  locations: null,
  loading: false,
  user: null,
  currentUser: null,
  signup: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_ACTION:
      return {
        ...state,
        authAction: action.authAction
      };
    case RESET_AUTH_ACTION:
      return {
        ...state,
        authAction: null
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.loading
      };
    case SET_USER:
      return {
        ...state,
        user: action.user
      };
    case RESET_USER:
      return {
        ...state,
        user: null,
        currentUser: null
      };
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.currentUser
      };
    case SET_SIGNUP:
      return {
        ...state,
        signup: {
          ...(state.signup ? state.signup : {}),
          ...action.signup
        }
      };
    case RESET_SIGNUP:
      return {
        ...state,
        signup: null
      };
    case SET_SIGNUP_NOTIFY:
      return {
        ...state,
        ...action.emailData
      };
    case SET_ORG:
      return {
        ...state,
        organization: action.organization
      };
    case SET_LOCATIONS:
      return {
        ...state,
        locations: action.locations
      };
    case LOGOUT:
      return {
        ...initialState,
        organization: state.organization,
        locations: state.locations
      };
    default:
      return state;
  }
};

export default reducer;
