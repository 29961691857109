import { pathOr } from 'ramda';
import * as contentful from 'contentful';
import * as contentfulManagement from 'contentful-management';

export const CSPACE = process.env.REACT_APP_CSPACE;

export const referenceType = (id) => {
  return {
    sys: {
      type: 'Link',
      linkType: 'Entry',
      id
    }
  };
};

export const referenceTypes = (references) => {
  return references.map((reference) => referenceType(reference.sys.id));
};

export const client = contentful.createClient({
  space: CSPACE,
  accessToken: process.env.REACT_APP_CDT,
  environments: 'master' // TODO move to heroku var, pull dev data on local
});

export const clientManagement = contentfulManagement.createClient({
  accessToken: process.env.REACT_APP_CMT
});

/**
 *
 * @param {object} item  // ex, { fields: {...}, sys: {...} }
 */
export const flattenItem = (response) => {
  const fields = pathOr(null, ['fields'], response);
  const sys = pathOr(null, ['sys'], response);
  const id = pathOr(null, ['sys', 'id'], response);
  const createdAt = pathOr(null, ['sys', 'createdAt'], response);
  const updatedAt = pathOr(null, ['sys', 'updatedAt'], response);
  const contentType = pathOr(null, ['sys', 'contentType', 'sys', 'id'], response);
  const data = {
    ...(fields || {})
  };

  if (id) data.id = id;
  if (createdAt) data.createdAt = createdAt;
  if (updatedAt) data.updatedAt = updatedAt;
  if (contentType) data.contentType = contentType;
  if (fields) data.fields = fields; // TODO Deprecate
  if (sys) data.sys = sys; // TODO Deprecate

  return data;
};

/**
 *
 * @param {array of objects} arr // ex, [ { fields: {...}, sys: {...} }, { fields: {...}, sys: {...} } ]
 */
export const flattenItems = (arr) => {
  const flattenedArr = arr.map((item) => flattenItem(item));

  return flattenedArr;
};
