import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const Phone = ({ onChange }) => {
  // Redux
  const signup = useSelector((state) => state.signup);
  // Signup
  const {
    role, userPhone, canChange, canChangePhone
  } = signup;
  // Local State
  const [showPhoneField, setShowPhoneField] = useState(!userPhone);

  if (role !== 'owner' && !userPhone) return null;

  return (
    <div className="mb-3 text-left">
      <div className="text-muted small">
        <label
          htmlFor="phone"
          className="w-100 text-left text-gray-dark mb-1 font-weight-bold"
        >
          {role === 'owner' && 'Work'} Phone
          {!showPhoneField && (canChange || canChangePhone) && (
            <button
              type="button"
              className="btn-link p-0 ml-2"
              onClick={() => {
                setShowPhoneField(true);
              }}
            >
              Change
            </button>
          )}
        </label>

        {!showPhoneField && <p className="m-0">{userPhone}</p>}

        <input
          type={showPhoneField ? 'text' : 'hidden'}
          id="phone"
          name="phone"
          className="form-control"
          placeholder="412-555-1212"
          defaultValue={userPhone}
          onChange={(e) => {
            const { value } = e.currentTarget;
            onChange(value);
          }}
          required
        />
      </div>
    </div>
  );
};

export default Phone;
