import { SET_AUTH_ACTION, RESET_AUTH_ACTION } from './types';

export const setAuthAction = ({
  actionCode, actionCodeChecked, email, continueUrl, lang
}) => {
  return (dispatch) => {
    dispatch({
      type: SET_AUTH_ACTION,
      authAction: {
        actionCode,
        actionCodeChecked,
        email,
        continueUrl,
        lang
      }
    });
  };
};

export const resetAuthAction = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_AUTH_ACTION,
      authAction: null
    });
  };
};
