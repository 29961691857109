import { isStudent } from '@apprentage/utils';
import * as twApi from '../tw-api';
import { PLATFORM_NAME } from '../../constants/globals';
import { GMAIL_SEND } from '../../constants/api';
import baseTemplate from './templates/baseTemplate';
import resetPasswordTemplate from './templates/resetPasswordTemplate';
import newUserNotifyOrgHtml from './templates/newUserNotifyOrgHtml';
import newUserWelcomeHtml from './templates/newUserWelcomeHtml';
import { locationTerminology } from '../locations';
import newOrgWelcomeHtml from './templates/newOrgWelcomeHtml';
import { getRegisterOwnerLink } from '../auth';

/**
 * Sends Welcome email to newly registered user with information
 * @param {string} orgName // Org Name
 * @param {string} orgSlug // Org Type (community, workforce)
 * @param {string} locationId // From newly created Organization
 * @param {string} name // Newly registered user's name
 * @param {string} email // Newly registered user's email
 * @param {string} phone // Newly registered user's phone
 */
export const newOrgWelcomeEmail = ({
  orgName,
  orgSlug,
  name,
  email,
  phone,
  locationId
}) => {
  const registerLink = getRegisterOwnerLink({
    slug: orgSlug,
    email,
    name,
    phone,
    locationId
  });
  const emailParts = {
    to: email,
    from: {
      name: PLATFORM_NAME
    },
    subject: `Welcome to ${PLATFORM_NAME}`,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      title: 'Welcome',
      trackingSlug: 'newOrg',
      bodyContent: newOrgWelcomeHtml({
        orgName,
        registerLink,
        name
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Notify Organization Location Managers & Observers
 * @param {string} orgName // Organization Name
 * @param {string} orgLogo // Organization Logo
 * @param {string} email // User's email
 * @param {string} resetPasswordLink // Firebase generate url pointing to lms-auth to trigger enter new password
 */
export const resetPasswordEmail = ({
  orgName = PLATFORM_NAME,
  orgLogo,
  email,
  resetPasswordLink
}) => {
  const emailParts = {
    from: {
      name: orgName
    },
    to: email,
    subject: `Reset your password for ${orgName}`,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      orgLogo,
      title: 'Reset Password',
      trackingSlug: 'resetPassword',
      bodyContent: resetPasswordTemplate({ resetPasswordLink })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Notify Organization Location Managers & Observers
 * @param {string} orgName // Org Name
 * @param {string} orgLogo // Org Logo URL
 * @param {string} orgType // Org Type (community, workforce)
 * @param {string} messageUserLink // Deep link into Turbine Admin to message user
 * @param {string} locationName // Organization Location Name
 * @param {boolean} defaultLocation // Organization Location flag if default location
 * @param {string} name // User's Name
 * @param {string} userRoleReadable // User's Role (human readable)
 * @param {string} membership // User's Membership level
 * @param {string} bcc // 'alan.mabry@gmail.com' comma separated emails
 * @param {string} to // Org Email or hello @ Turbine
 */
export const newUserNotifyOrg = ({
  orgName,
  orgLogo,
  orgType,
  messageUserLink,
  locationName,
  defaultLocation,
  name,
  userRoleReadable,
  membership,
  bcc,
  to
}) => {
  const locationTerm = locationTerminology({ defaultLocation, orgType });
  const showMembership = orgType !== 'workforce';
  const emailParts = {
    from: {
      name: PLATFORM_NAME
    },
    to,
    subject: `A ${userRoleReadable} joined ${orgName}'s Workforce Space`,
    bcc,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      orgLogo,
      title: `New ${userRoleReadable} Joined`,
      trackingSlug: 'newUserJoined',
      bodyContent: newUserNotifyOrgHtml({
        orgName,
        showMembership,
        locationTerm,
        locationName,
        userRoleReadable,
        messageUserLink,
        name,
        membership
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};

/**
 * Sends Welcome email to newly registered user with information
 * @param {string} orgName // Org Name
 * @param {string} orgLogo // Org Logo URL
 * @param {string} orgType // Org Type (community, workforce)
 * @param {boolean} integrateSlack // Org flag if slack is integrated
 * @param {string} loginLink // Login URL to Turbine LMS
 * @param {string} userRoleReadable // Role (human readable) of newly registered user
 * @param {string} role // Role (machine readable) of newly registered user
 * @param {string} name // Name of newly registered user
 * @param {string} to // Email address of newly registered user
 */
export const sendEmailWelcomeUser = ({
  orgName,
  orgLogo,
  orgType,
  integrateSlack,
  loginLink,
  userRoleReadable,
  role,
  name,
  to
}) => {
  const emailParts = {
    to,
    from: {
      name: orgName
    },
    subject: `Welcome to ${orgName}'s Workforce Space!`,
    body: '',
    htmlBody: baseTemplate({
      orgName,
      orgLogo,
      title: 'Welcome',
      trackingSlug: 'newUserJoined',
      bodyContent: newUserWelcomeHtml({
        orgType,
        orgName,
        loginLink,
        name,
        userRoleReadable,
        showSlackInfo: integrateSlack,
        showCommunityInfo: orgType === 'community' && isStudent([role])
      })
    })
  };

  return new Promise((resolve, reject) => {
    return twApi
      .post(GMAIL_SEND, '', JSON.stringify(emailParts))
      .then((response) => {
        if (response && response.status === 200) {
          resolve();
        } else {
          console.error(response.status); // ex: 'error_invitation_fail'

          reject({
            message: response.message,
            status: response.status
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  });
};
