import { client, flattenItems } from './contentful';
import { fetchSupabaseEntries } from './supabaseProxy';

export const expertTerminology = ({ orgType }) => {
  return orgType !== 'workforce' ? 'Teacher' : 'Expert';
};

export const fetchOrgUsers = async ({
  orgId,
  roles = ['owner'],
  select = ['role', 'createdAt']
}) => {
  try {
    const response = await fetchSupabaseEntries({
      'f.role[ov]': roles.join(','),
      'f.orgId[eq]': orgId,
      select: select.join(',')
    }, 'users');

    const items = response?.items;
    const users = Array.isArray(items) && items.length ? items : null;
    return users;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export const getUserGroups = ({ orgId }) => {
  return new Promise((resolve, reject) => {
    const config = {
      content_type: 'group',
      'fields.type': 'user',
      'fields.orgId': orgId
    };

    return client.getEntries(config).then(({ items }) => {
      const userGroups = items.length ? flattenItems(items) : null;

      resolve(userGroups);
    }).catch((error) => {
      reject(error);
    });
  });
};
