import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setSignUp } from '../../../actions';
import { SIGNUP } from '../../../constants/routes';
import { locationSignUpParams } from '../../../services/auth';

const RegisterLink = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  // Redux
  const organization = useSelector((state) => state.organization);
  const locations = useSelector((state) => state.locations);
  // Misc
  const orgType = organization?.type || '';
  const orgId = organization?.id || '';
  const externalRegisterUrl = organization?.externalRegisterUrl || '';
  const restrictRegister = organization?.restrictRegister || false;

  const renderExternalRegisterLink = () => {
    return (
      <a
        className="btn-link"
        href={externalRegisterUrl}
        title="Sign Up"
      >
        Sign up
      </a>
    );
  };

  const renderInternalRegisterLink = () => {
    return (
      <button
        className="btn-link p-0 ml-1"
        type="button"
        title="Sign Up"
        onClick={() => {
          const newSignUpData = {
            orgId,
            membership: 'starter',
            role: 'student',
            ...locationSignUpParams({ orgType, locations })
          };

          dispatch(setSignUp(newSignUpData)).then(() => {
            history.replace(SIGNUP);
          });
        }}
      >
        Sign Up
      </button>
    );
  };

  if (organization) {
    // Organization has a custom registration URL outside of Turbine
    if (externalRegisterUrl) {
      return (
        <div className="mt-4">
          Don't have an account? {renderExternalRegisterLink()}
        </div>
      );
    }

    // Company has disabled Registration and explicitly invites users
    if (!restrictRegister) {
      return (
        <div className="mt-4">
          Don't have an account? {renderInternalRegisterLink()}
        </div>
      );
    }
  }

  return null;
};

export default RegisterLink;
