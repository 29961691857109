import { expertTerminology } from '../../users';
import Divider from '../Divider';
import ButtonPrimary from '../ButtonPrimary';

const newUserWelcomeHtml = ({
  linkText = 'Login',
  orgName,
  orgType,
  name,
  userRoleReadable,
  loginLink,
  showSlackInfo,
  showCommunityInfo
}) => {
  const expertTerm = expertTerminology({ orgType });
  let slackInfo = '';
  let communityInfo = '';

  if (showSlackInfo) {
    slackInfo = `
      <p>
        <strong>Sign up</strong> for our Slack community and introduce yourself! Slack is the best way for you to ask questions, get announcements, and get to know your fellow students and instructors. Every class of ours has its own channel on Slack for students to talk about what they're working on.
      </p>
    `;
  }

  if (showCommunityInfo) {
    communityInfo = `
      <p>
        <strong>Attend class</strong>: Class schedules are on the class overview page and a video meeting link will show when the class is live.
      </p>
    `;
  }

  return `
    <p>
      Hi ${name},
    </p>
    <p>
      Welcome to ${orgName}'s Workforce Space!
    </p>
    <p>
      Inside you will find courses, resources and a ${userRoleReadable} Dashboard to keep you on track.
    </p>

    <div
      align="center"
      style="line-height:10px; padding-top:20px;padding-bottom:20px;"
    >
      ${ButtonPrimary({ text: linkText, href: loginLink })}
    </div>

    ${slackInfo}

    ${communityInfo}

    <p>
      If you have any questions, send a message to any Course ${expertTerm}.
    </p>

    <p>
      Team ${orgName}
    </p>

    ${Divider()}

    <p>
      "${linkText}" button not working?
    </p>

    <p>
      Copy and paste this link in your browser:
    </p>

    <p>
      ${loginLink}
    </p>
  `;
};

export default newUserWelcomeHtml;
