import { toast } from 'react-toastify';
import { deleteFirebaseEntry } from './firebaseProxy';

const collection = 'invites';

/**
 *
 * @param {string} id // "docId"
 * @param {object} data
 */
export const deleteInvite = (id) => {
  return new Promise((resolve) => {
    deleteFirebaseEntry({
      collection,
      id
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        toast.error('Something went wrong');
        console.error(`delete ${collection}`, error);
      });
  });
};
