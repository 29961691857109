export const FIELDS_ORG = [
  'fields.name',
  'fields.email',
  'fields.slug',
  'fields.type',
  'fields.orgLogo',
  'fields.privacyPolicy',
  'fields.tos',
  'fields.externalRegisterUrl',
  'fields.restrictRegister',
  'fields.userGroupIds',
  'fields.integrateSlack'
];

export const FIELDS_LOCATION = [
  'fields.defaultLocation',
  'fields.name',
  'fields.observers', // TODO location flatten (observerIds)
  'fields.managerIds',
  'fields.managers' // TODO location flatten (managerIds)
];
