import React from 'react';
import { PLATFORM_NAME } from '../../../constants/globals';
import { TURBINE_MARKETING_SITE } from '../../../constants/urls';
import './style.css';

const PoweredBy = () => {
  return (
    <p className="poweredby">
      Powered by <a target="_blank" rel="noopener noreferrer" href={`${TURBINE_MARKETING_SITE}?track=auth`} title={`Powered by ${PLATFORM_NAME}`}>{PLATFORM_NAME}</a>
    </p>
  );
};

export default PoweredBy;
