// middleName,
// suffix,
function formatUser(data, entry) {
  const {
    membership,
    role,
    groupIds, // String[]
    classId,
    classIds, // String[]
    cohortId,
    eUnitIds, // String[]
    extRegClassId,
    apprenticeshipId,
    apprenticeshipIds, // String[]
    ...remainingData
  } = data;

  const dataToSave = {
    ...remainingData
  };

  if (membership !== undefined) {
    dataToSave.membership = [membership];
  }

  if (role !== undefined) {
    dataToSave.role = [role];
  }

  // User Group IDs
  if (groupIds !== undefined && Array.isArray(groupIds)) {
    dataToSave.groupIds = Array.isArray(entry?.groupIds) ? entry?.groupIds : [];

    // De-dupe
    groupIds.forEach((id) => {
      if (!dataToSave.groupIds.includes(id)) {
        dataToSave.groupIds.push(id);
      }
    });
  }

  // Course Enrollment
  if (classId !== undefined || classIds !== undefined) {
    dataToSave.courseIds = Array.isArray(entry?.courseIds) ? entry?.courseIds : [];

    if (classId) {
      // De-dupe
      if (!dataToSave.courseIds.includes(classId)) {
        dataToSave.courseIds.push(classId);
      }
    }

    if (Array.isArray(classIds)) {
      // De-dupe
      classIds.forEach((id) => {
        if (!dataToSave.courseIds.includes(id)) {
          dataToSave.courseIds.push(id);
        }
      });
    }
  }

  // Educational Unit IDs
  if (eUnitIds !== undefined && Array.isArray(eUnitIds)) {
    dataToSave.educationUnitIds = Array.isArray(entry?.educationUnitIds) ? entry?.educationUnitIds : [];

    // De-dupe
    eUnitIds.forEach((id) => {
      if (!dataToSave.educationUnitIds.includes(id)) {
        dataToSave.educationUnitIds.push(id);
      }
    });
  }

  // EXTERNAL REGISTERED COURSES
  if (extRegClassId !== undefined) {
    dataToSave.externalRegisteredCourseIds = Array.isArray(entry?.externalRegisteredCourseIds) ? entry?.externalRegisteredCourseIds : [];
    // De-dupe
    if (!dataToSave.externalRegisteredCourseIds.includes(extRegClassId)) {
      dataToSave.externalRegisteredCourseIds.push(extRegClassId);
    }
  }

  if (apprenticeshipId !== undefined || apprenticeshipIds !== undefined) {
    // Mark user as apprentice (gather private data)
    dataToSave.isApprentice = true;

    dataToSave.apprenticeshipIds = Array.isArray(entry?.apprenticeshipIds) ? entry?.apprenticeshipIds : [];

    if (apprenticeshipId) {
      // De-dupe
      if (!dataToSave.apprenticeshipIds.includes(apprenticeshipId)) {
        dataToSave.apprenticeshipIds.push(apprenticeshipId);
      }
    }

    if (Array.isArray(apprenticeshipIds)) {
      // De-dupe
      apprenticeshipIds.forEach((id) => {
        if (!dataToSave.apprenticeshipIds.includes(id)) {
          dataToSave.apprenticeshipIds.push(id);
        }
      });
    }
  }

  // COHORTS
  if (cohortId !== undefined) {
    dataToSave.cohortIds = Array.isArray(entry?.cohortIds) ? entry?.cohortIds : [];
    // De-dupe
    if (!dataToSave.cohortIds.includes(cohortId)) {
      dataToSave.cohortIds.push(cohortId);
    }
  }

  return dataToSave;
}

export default formatUser;
